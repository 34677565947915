<template>
  <LanguageSwitcher
    v-if="isSurveyMultilingual"
    :user-survey-slug="userSurveySlug"
    :languages="languages"
  />
</template>

<script>
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { mapMutations } from 'vuex';
import { toggleLoading, updateProgressBar } from '@/plugins/vuex/mutationTypes';
import LanguageSwitcher from '@/views/LanguageSwitcher.vue';

export default {
  name: 'SurveyTestTechPage',
  components: { LanguageSwitcher },
  inject: ['Names'],

  data() {
    return {
      userSurveySlug: '',
      languages: [],
      orgstructure: {},
    };
  },

  computed: {
    surveySlug() {
      return this.$route.params.slug;
    },
    isSurveyMultilingual() {
      return this.languages.length > 1;
    },
    isRouteOrgStructure() {
      return !!(this.orgstructure?.orgstructurePageOrder === 0 && this.orgstructure?.orgstructureRequired);
    },
  },

  async created() {
    await this.initSurvey();
    await this.getSurveyAvailableLanguages();

    if (!this.isSurveyMultilingual) {
      this.$router.push({
        name: this.isRouteOrgStructure ? this.Names.R_SURVEY_ORGSTRUCTURE : this.Names.R_USER_SURVEY_PAGE,
        params: { slug: this.userSurveySlug },
        query: { testing: true },
      });
    }
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar]),
    async initSurvey() {
      this[toggleLoading](true);

      try {
        const { data: { slug } } = await apiService.surveyByUserSlugCreate(this.surveySlug, {
          params: { testing: true },
        });
        this.userSurveySlug = slug;

        const { data: welcomeResponse } = await apiService.surveyByUserSlugWelcomeGet(this.surveySlug);
        const {
          surveyName,
          orgstructurePageOrder,
          orgstructureRequired,
        } = welcomeResponse;
        this.orgstructure = {
          orgstructurePageOrder,
          orgstructureRequired,
        };
        this[updateProgressBar]({ surveyName });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async getSurveyAvailableLanguages() {
      this[toggleLoading](true);
      try {
        const { data } = await apiService.surveyLanguagesGet(this.userSurveySlug);
        this.languages = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
  },
};
</script>
